<template>
    <div id="services" class="cards-1 bg-gray">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Explora nuestros servicios</h2>
                </div> <!-- end of col -->
            </div> <!-- end of row -->
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-icon">
                            <span class="fas fa-headphones-alt"></span>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title">Contacta con nosotros</h5>
                            <p>Contacta con nosotros y recibe un presupuesto personalizado sin compromiso.</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon red">
                            <span class="far fa-clipboard"></span>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title">Evalua las oportunidades</h5>
                            <p>Trabajamos en un mercado muy competido, por ello nuestros precios se ajustan a cada cliente.</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon green">
                            <span class="far fa-comments"></span>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title">Comparte experiencia</h5>
                            <p>Una vez trabajo terminado, seguramente, queras compartir la buena experiencia que as tenido con nosotros.</p>
                        </div>
                    </div>
                    <!-- end of card -->

                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of cards-1 -->
    <!-- end of services -->


    <!-- Details 1 -->
    <div id="details" class="basic-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-xl-7">
                    <div class="image-container">
                        <img class="img-fluid images_details" src="/img/mudanza.png" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
                <div class="col-lg-6 col-xl-5">
                    <div class="text-container">
                        <div class="section-title">MUDANZAS RETO</div>
                        <h2>Mudanzas a particulares y empresas</h2>
                        <p>Sabemos que una mudanza o un traslado es una operación complicada, y por eso queremos ponerte las cosas lo más fáciles posible. <br><br>
                         La transparencia, la flexibilidad y un trato cercano y honesto son valores fundamentales en nuestro código ético.</p>
                        <router-link class="btn-solid-reg" to="/Mudanzas-particulares-y-empresas-Cantabria">...Más</router-link>
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-1 -->
    <!-- end of details 1 -->

    <!-- Details 2 -->
    <div class="basic-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-xl-5">
                    <div class="text-container">
                        <div class="section-title">MUDANZAS RETO</div>
                        <h2>Vaciados de pisos, garajes, naves y etc.</h2>
                        <p>Brindamos el servicio de vaciados de pisos, garajes o cualquier tipo de inmueble en Santander y provincia. <br><br>
                            No importa si tu inmueble está habitado o deshabitado, realizamos un trabajo reponsable, profesional, en un tiempo récord y sin incomodar a sus vecinos o personas que vivan en el inmueble. <br><br>
                            Hacemos uso de puntos limpios más cercanos de la zona y también damos la segunda vida a las cosas que se puede reciclar.        
                        </p>
                        <router-link class="btn-outline-reg" to="/Vaciados-de-pisos-garages-recogida-de-muebles-y-chatarra-Cantabria">...Más</router-link>
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
                <div class="col-lg-6 col-xl-7">
                    <div class="image-container">
                        <img class="img-fluid images_details2" src="/img/vaciado.png" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-2 -->
    <!-- end of details 2 -->

<div id="features" class="cards-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">

                    <div class="card">
                        <img class="img-fluid" src="/img/reformas.png" alt="Reformas Mudanzas Centro de Reto Cantabria">
                        <div class="card-body">
                            <h5 class="card-title">Reformas</h5>
                            <p class="card-text">Ofrecemos calidad a precios competitivos, buscando siempre las soluciones a todas tus necesidades, porque nos apasiona nuestro trabajo y queremos que nuestros clientes queden satisfechos.
                                <router-link class="blue no-line" to="/Reformas-construccion-Cantabria">...Más</router-link></p>
                        </div>
                    </div>
                    <div class="card">
                        <img class="img-fluid" src="/img/pintura.png" alt="Pinturas Centro de Reto Cantabria">
                        <div class="card-body">
                            <h5 class="card-title">Pintura</h5>
                            <p class="card-text">Los esmaltes con los que trabajamos en tipo semi-laca son sintéticos, que aportan una gran resistencia y elasticidad así mismo un tacto muy agradable y de gran facilidad de limpieza.
                                 <router-link class="blue no-line" to="/Pinturas-Cantabria">...Mas</router-link></p>
                        </div>
                    </div>
                    <div class="card">
                        <img class="img-fluid" src="/img/removal2.png" alt="Vaciados de pisos garages recogida de muebles chatarra Centro de Reto Cantabria">
                        <div class="card-body">
                            <h5 class="card-title">Recogida de enseres</h5>
                            <p class="card-text">Hacemos recogida de muebles, electrodomésticos y otros enseres. 
                                Si los enseres a retirar son aprovechables y se encuentran en buen uso,  su retirada puede ser gratuita.
                                <router-link class="blue no-line" to="/Vaciados-de-pisos-garages-recogida-de-muebles-y-chatarra-Cantabria">...Más</router-link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>

 
    <div class="accordion-1">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <h2 class="h2-heading">Servicio de limpieza Mudanzas Reto</h2>
                    <p class="p-heading">En Mudanzas Reto te ofrecemos multitud de servicios para el hogar en Santander y provincia en una sola llamada. 
                         Entre ellos, queremos destacar aquí nuestro servicio de limpieza para la casa que te ayudará a mantener tu hogar impecable, siempre que lo desees.
                         Confianza, calidad y flexibilidad son los 3 pilares que caracterizan nuestros servicios.</p>
                </div> 
            </div> 
            <div class="row">
                <div class="col-xl-5">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Servicio de limpieza para tu hogar
                                </button>
                            </div> 
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Tenemos soluciones adaptadas a tus necesidades y a las de tu casa en cuestión de limpieza en la ciudad y los alrededores de Santander.
                                </div>
                            </div> 
                        </div> 
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingTwo">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                   Limpieza por horas a domicilio
                                </button>
                            </div> 
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">En Mudanzas Centro Reto, trabajamos para que disfrutes de unas prestaciones flexibles, con la calidad que realmente buscas para tu casa, de la manera más cómoda, sencilla y sin compromiso de permanencia.</div>
                            </div> 
                        </div> 
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingThree">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                    Limpiezas generales en Santander
                                </button>
                            </div>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">En Mudanzas Centro Reto, no es la primera vez que tu hogar te pide una buena limpieza a fondo, con especial hincapié en estancias como los baños y la cocina, que requieren mayor cuidado relativo a la higiene. <br><br>
                                     Frente a este tipo de necesidades, ponemos a tu disposición un servicio de limpiezas generales para tu casa, chalet o adosado en Santander, que seguro te gustará.</div>
                            </div> 
                        </div> 
           
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingFour">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Presupuesto de limpieza sin compromiso
                                </button>
                            </div> <!-- end of accordion-header -->
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">En Mudanzas Centro Reto, la limpieza y tareas no tienen por qué ser un problema con Mudanzas Reto en Santander. ¿Qué podemos ofrecerte para tu hogar? <br><br>
                                     Llámanos, cuéntanos tus necesidades y obtén un presupuesto de limpieza para tu casa en Santander sin compromiso.</div>
                            </div>
                        </div> 
                       
                    </div> 
                </div> 
                <div class="col-xl-7">
                    <div class="image-container">
                        <img class="img-fluid" src="/img/clean1.png" alt="Mudanzas Centro Reto">
                    </div> 
                </div> 
            </div> 
        </div> 
    </div>
</template>
